var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.playersArePresent ? _c('div', {
    staticClass: "vtb-item"
  }, [_c('div', {
    class: _vm.getBracketNodeClass(_vm.bracketNode)
  }, [_c('game-players', {
    attrs: {
      "bracket-node": _vm.bracketNode,
      "highlighted-player-id": _vm.highlightedPlayerId,
      "show-results-button": _vm.showEditResults,
      "is-loading-match": _vm.isLoadingMatch,
      "loading-match-id": _vm.loadingMatchId,
      "organizer-mode": _vm.organizerMode
    },
    on: {
      "onDeselectedPlayer": _vm.unhighlightPlayer,
      "onMatchResultClick": _vm.matchResultClick,
      "onSelectedPlayer": _vm.highlightPlayer
    },
    scopedSlots: _vm._u([{
      key: "player",
      fn: function fn(_ref) {
        var player = _ref.player;
        return [_vm._t("player", null, {
          "player": player
        }), _vm._t("score", null, {
          "player": player
        })];
      }
    }, {
      key: "player-extension-bottom",
      fn: function fn(_ref2) {
        var match = _ref2.match;
        return [_vm._t("player-extension-bottom", null, {
          "match": match
        })];
      }
    }], null, true)
  })], 1), _vm.bracketNode.games[0] || _vm.bracketNode.games[1] ? _c('div', {
    staticClass: "vtb-item-children"
  }, [_vm.bracketNode.games[0] ? _c('div', {
    staticClass: "vtb-item-child"
  }, [_c('bracket-node', {
    attrs: {
      "bracket-node": _vm.bracketNode.games[0],
      "highlighted-player-id": _vm.highlightedPlayerId,
      "show-edit-results": _vm.showEditResults,
      "is-loading-match": _vm.isLoadingMatch,
      "loading-match-id": _vm.loadingMatchId,
      "organizer-mode": _vm.organizerMode
    },
    on: {
      "onDeselectedPlayer": _vm.unhighlightPlayer,
      "onEditPlacementClick": _vm.editPlacementClick,
      "onSelectedPlayer": _vm.highlightPlayer,
      "onMatchResultClick": _vm.matchResultClick
    },
    scopedSlots: _vm._u([{
      key: "player",
      fn: function fn(_ref3) {
        var player = _ref3.player;
        return [_vm._t("player", null, {
          "player": player
        })];
      }
    }, {
      key: "player-extension-bottom",
      fn: function fn(_ref4) {
        var match = _ref4.match;
        return [_vm._t("player-extension-bottom", null, {
          "match": match
        })];
      }
    }], null, true)
  })], 1) : _vm._e(), _vm.bracketNode.games[1] ? _c('div', {
    staticClass: "vtb-item-child"
  }, [_c('bracket-node', {
    attrs: {
      "bracket-node": _vm.bracketNode.games[1],
      "highlighted-player-id": _vm.highlightedPlayerId,
      "show-edit-results": _vm.showEditResults,
      "organizer-mode": _vm.organizerMode,
      "loading-match-id": _vm.loadingMatchId,
      "is-loading-match": _vm.isLoadingMatch
    },
    on: {
      "onDeselectedPlayer": _vm.unhighlightPlayer,
      "onSelectedPlayer": _vm.highlightPlayer,
      "onMatchResultClick": _vm.matchResultClick
    },
    scopedSlots: _vm._u([{
      key: "player",
      fn: function fn(_ref5) {
        var player = _ref5.player;
        return [_vm._t("player", null, {
          "player": player
        })];
      }
    }, {
      key: "player-extension-bottom",
      fn: function fn(_ref6) {
        var match = _ref6.match;
        return [_vm._t("player-extension-bottom", null, {
          "match": match
        })];
      }
    }], null, true)
  })], 1) : _vm._e()]) : _vm._e()]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }