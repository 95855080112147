<template>
  <div
    v-if="playersArePresent"
    class="vtb-item"
  >
    <div :class="getBracketNodeClass(bracketNode)">
      <game-players
        :bracket-node="bracketNode"
        :highlighted-player-id="highlightedPlayerId"
        :show-results-button="showEditResults"
        :is-loading-match="isLoadingMatch"
        :loading-match-id="loadingMatchId"
        :organizer-mode="organizerMode"
        @onDeselectedPlayer="unhighlightPlayer"
        @onMatchResultClick="matchResultClick"
        @onSelectedPlayer="highlightPlayer"
      >
        <template #player="{ player }">
          <slot
            :player="player"
            name="player"
          />
          <slot
            :player="player"
            name="score"
          />
        </template>
        <template #player-extension-bottom="{ match }">
          <slot
            :match="match"
            name="player-extension-bottom"
          />
        </template>
      </game-players>
    </div>

    <div
      v-if="bracketNode.games[0] || bracketNode.games[1]"
      class="vtb-item-children"
    >
      <div
        v-if="bracketNode.games[0]"
        class="vtb-item-child"
      >
        <bracket-node
          :bracket-node="bracketNode.games[0]"
          :highlighted-player-id="highlightedPlayerId"
          :show-edit-results="showEditResults"
          :is-loading-match="isLoadingMatch"
          :loading-match-id="loadingMatchId"
          :organizer-mode="organizerMode"
          @onDeselectedPlayer="unhighlightPlayer"
          @onEditPlacementClick="editPlacementClick"
          @onSelectedPlayer="highlightPlayer"
          @onMatchResultClick="matchResultClick"
        >
          <template #player="{ player }">
            <slot
              :player="player"
              name="player"
            />
          </template>
          <template #player-extension-bottom="{ match }">
            <slot
              :match="match"
              name="player-extension-bottom"
            />
          </template>
        </bracket-node>
      </div>
      <div
        v-if="bracketNode.games[1]"
        class="vtb-item-child"
      >
        <bracket-node
          :bracket-node="bracketNode.games[1]"
          :highlighted-player-id="highlightedPlayerId"
          :show-edit-results="showEditResults"
          :organizer-mode="organizerMode"
          :loading-match-id="loadingMatchId"
          :is-loading-match="isLoadingMatch"
          @onDeselectedPlayer="unhighlightPlayer"
          @onSelectedPlayer="highlightPlayer"
          @onMatchResultClick="matchResultClick"
        >
          <template #player="{ player }">
            <slot
              :player="player"
              name="player"
            />
          </template>
          <template #player-extension-bottom="{ match }">
            <slot
              :match="match"
              name="player-extension-bottom"
            />
          </template>
        </bracket-node>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import GamePlayers from './GamePlayers'

export default {
  name: 'BracketNode',
  components: { GamePlayers },
  props: ['bracketNode', 'highlightedPlayerId', 'showEditResults', 'organizerMode', 'isLoadingMatch', 'loadingMatchId'],
  computed: {
    playersArePresent() {
      return this.bracketNode.player1 && this.bracketNode.player1
    },
  },
  methods: {
    getBracketNodeClass(bracketNode) {
      if (bracketNode.games[0] || bracketNode.games[1]) {
        return 'vtb-item-parent'
      }

      if (bracketNode.hasParent) {
        return 'vtb-item-child'
      }

      return ''
    },
    getPlayerClass(player) {
      if (player.winner === null || player.winner === undefined) {
        return ''
      }

      let clazz = player.winner ? 'winner' : 'defeated'

      if (this.highlightedPlayerId === player.id) {
        clazz += ' highlight'
      }

      return clazz
    },
    highlightPlayer(playerId) {
      this.$emit('onSelectedPlayer', playerId)
    },
    unhighlightPlayer() {
      this.$emit('onDeselectedPlayer')
    },
    editPlacementClick(bracketNode) {
      this.$emit('onEditPlacementClick', bracketNode?.matchId)
    },
    matchResultClick(matchId) {
      this.$emit('onMatchResultClick', matchId)
    },
  },
}
</script>

<style>
  .vtb-item {
    display: flex;
    flex-direction: row-reverse;
  }

  .vtb-item p {
    padding: 20px;
    margin: 0;
    background-color: #999999;
  }

  .vtb-item-parent {
    position: relative;
    margin-left: 50px;
    display: flex;
    align-items: center;
  }

  .vtb-item-players {
    flex-direction: column;
    background-color: #151d32;
    margin: 0;
    color: white;
  }

  .vtb-item-players .vtb-player {
    padding: 10px;
    max-width: 200px !important;
  }

  .vtb-item-players .winner {
    background-color: #151d32;
  }

  .vtb-item-players .defeated {
    background-color: #151d32;
  }

  .vtb-item-players .winner.highlight {
    background-color: #151d32;
  }

  .vtb-item-players .defeated.highlight {
    background-color: #151d32;
  }

  .vtb-item-parent:after {
    position: absolute;
    content: '';
    width: 25px;
    height: 2px;
    left: 0;
    top: 50%;
    background-color: gray;
    transform: translateX(-100%);
  }

  .vtb-item-children {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .vtb-item-child {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
  }

  .vtb-item-child:before {
    content: '';
    position: absolute;
    background-color: gray;
    right: 0;
    top: 50%;
    transform: translateX(100%);
    width: 25px;
    height: 2px;
  }

  .vtb-item-child:after {
    content: '';
    position: absolute;
    background-color: gray;
    right: -25px;
    height: calc(50% + 22px);
    width: 2px;
    top: 50%;
  }

  .vtb-item-child:last-child:after {
    transform: translateY(-100%);
  }

  .vtb-item-child:only-child:after {
    display: none;
  }
</style>
